import React from 'react';
import styled from 'styled-components';
import {useI18next, Helmet} from 'gatsby-plugin-react-i18next';
import {styles, media, links} from 'global.config';
import Stars from 'components/Stars';
import Button from 'components/Button';
import AsSeenOn from 'components/Alternatives/AsSeenOn';
import PriceCalculator from 'components/PriceCalculator';

import TopSectionImage from 'images/email-marketing-software-comparison-comparison.svg';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 70px;
  position: relative;
  min-height: calc(100vh - 170px);
  margin: auto;
  max-width: ${styles.containerWidth};
  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 235px;
    margin-bottom: 20px;
  }
`;

const IndexSectionSummary = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 400px;
  margin: 30px auto;
  font-weight: 300;
`;

const Page: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <Helmet title={t('Email Marketing Software Comparison')}>
        <meta
          name="description"
          content={t(
            'Compare the pricing for the most popular Email Marketing software tools on the planet. Click here to learn more!'
          )}
        />
      </Helmet>
      <div className="index-page">
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={TopSectionImage}
                alt={t('Email Marketing Software Comparison')}
                title={t('Email Marketing Software Comparison')}
                width={303}
                height={201}
              />
              <h1>{t('Like Comparing Stuff?')}</h1>
              <IndexSectionSummary>
                {t(
                  'We’ve put this page together so you can compare away from the comfort of your homes!'
                )}
              </IndexSectionSummary>
              <Button href={links.signUp} title={t('Start Comparing!')}>
                {t('Start Sending!')}
              </Button>
            </IndexSectionContainer>
          </IndexSection>
          <AsSeenOn />
        </Stars>
        <PriceCalculator />
      </div>
    </>
  );
};

export default Page;
